import React from 'react'
import { css } from 'glamor'
import Layout from "../components/layout"
import { rhythm } from '../utils/typography'
import roadmap2010 from './roadmap2010.svg'
import roadmap2012 from './roadmap2012.svg'
import roadmap2015 from './roadmap2015.svg'
import roadmap2018 from './roadmap2018.svg'
import roadmap2019 from './roadmap2019.svg'

let centredImg = css({
  display: 'block',
  margin: 'auto',
  marginBottom: rhythm(2)
});

let readable = css({
  maxWidth: '38em',
  margin: 'auto'
})

const About = () => (
  <Layout>
    <div className={readable}>
      <h1>Designer and builder for the web</h1>
      <p>
        Hi, I'm Chris Feddersen. I’m a Melbournian, husband and Dad currently
        working as a freelance web designer and previously as a senior designer at{' '}
        <a href="http://www.artisandigital.com.au/">Artisan Digital</a>.
      </p>
      <p>
        A typical day consists of designing user interfaces and design systems
        for a website and translating that design into robust, responsive and
        scalable front-end code. I <b>care about the details</b> when it comes
        to <b>user experience, interactions, aesthetics and layout</b>. Having
designed and built data rich wagering based applications since 2011, I have developed an expert eye for  
         <b> intuitive UI design</b>.
      </p>
      {/* <p>
        As a designer, I am skilled in graphical editing and authoring tools
        such as Sketch, Photoshop and Illustrator, while right at home
        as part of an agile team's build process. Git, Grunt, Jekyll, SASS,
        Webpack and React are all part of my daily toolkit.
      </p> */}
      <p>
        A love of learning and a desire to be <b>continually improving</b> has
        allowed me to bring a <b>process-driven focus</b> and be a positive
cultural influence on the teams I have worked with.
      </p>
      <p>
        That's the elevator pitch. If you want the back story keep on reading!
      </p>
    </div>
    <div className={readable}>
      <h2>2010</h2>
      <p>I began my journey by returning to study multimedia design before landing my first role as a web designer at Artisan Digital.</p>
      <blockquote>
        I realised that I was super{' '}
        <b>passionate about design and development</b> and I was fortunate
        enough to have found an industry where I could do both
      </blockquote>
    </div>

    <img
      src={roadmap2010}
      alt={`roadmap`}
      className={centredImg}
    />

    <div className={readable}>
      <h2>2011 - 2012</h2>
      <p>
        Artisan Digital provides a range of design services across different
        industries, but their speciality is designing customer-facing user
        interfaces for online bookmaking applications. Their primary client
        until 2014 was Sportingbet, one of Australia's largest online
        bookmakers. Artisan Digital would partner with software developers
        Stride Solutions, also based in Melbourne to produce the front-ends
        for the web and later, native apps. Since then the partnership has
        designed and built wagering applications for Betting Club, BlueBet and
        startups such as PickChamps, SportChamps and Swopstakes. I initially
        cut my teeth with simple photoshop cut-ups and marketing banners,
        </p>
      <p>
        I won't forget my first piece of work for our major client
        Sportingbet. It was a small promotion page for the website. I had
        created an elegant design and provided the HTML/CSS to a developer to
        populate it with real data. At the end of our weekly review consisting
        of designers, developers and project admin it was decided to present
        it on the giant screen. I was excited to see how it turned out. A
        couple of clicks later and ..... devastation. It looked completely
        broken. I had failed to consider how my design would look with real
        data. A rookie mistake. Yes, a username could be longer than "John
        Smith". Text overran different areas of the design, and the images
        didn't fit. It was a disaster. At least from my perspective. I went
        back to my desk to fix it quickly. I vowed my designs would never look
        like that again. It was a valuable lesson to learn early on.
        </p>
      <blockquote>
        I had failed to consider how my design would look with real data. A
        rookie mistake.
        </blockquote>
      <p>
        Before too long I was given significant projects for Sportingbet.
        Large projects, stretching 3 - 6 months that would include two to
        three designers per project where we would design the user-interfaces,
        build the HTML & CSS and then hand over to the development team to
        develop in Angular JS.
      </p>
      <p>
        After gaining experience and successfully contributing to numerous
        projects, I progressed to leading the design team on million dollar
        redesigns for the Sportingbet and Centrebet websites. I began
        supervising and mentoring junior designers and being responsible for
        the team's output, productivity and accuracy of estimating project
        tasks.
        </p>
    </div>

    <img
      src={roadmap2012}
      alt={`roadmap`}
      className={centredImg}
    />


    <div className={readable}>
      <h2>2013 - 2015</h2>
      <p>
        My most significant achievements came off the back of identifying a
        gap at the intersection of the design and development teams. The
        static designs we handed over would not go far enough to capture
        animations, interactions and the small details fully. The differences
        that make an application enjoyable and a memorable user experience.
        There were too many questions left unanswered with the developers
        required to fill in the blanks. Often not producing the best outcome.
        As a team, we needed to progress from delivering static interface
        designs to fully fledged web prototypes.
        </p>
      <p>
        While it was an iterative process over some years and projects, the
        design team made this progression possible by adopting a component
        driven approach and a "think like a developer" mentality when it came to design. We
        began building living style guides and component libraries in addition
        to our full-page compositions. We introduced tooling into our process
        that moved us from being designers that coded up HTML/CSS to an
        integral part of the development process.
        </p>
      <p>Some of the specifics include:</p>
      <ul>
        <li>Version controlling our designs and code with GIT</li>
        <li>
          Adopted BEM methodology for CSS and curating a framework which we
          shipped from project to project. In the process, we moved to writing
          styles in Sass
          </li>
        <li>
          Taking a component driven approach to design with a focus on
          component states. Our designs became robust and fully considered to
          handle dynamic data and edge case scenarios
          </li>
        <li>
          Producing asset libraries and living style guides that made project
          maintenance, scalability and handover simple
          </li>
        <li>
          Designed with realistic data. We began using Jekyll to template our
          designs and pull in this data
          </li>
        <li>
          We automated our workflow and build tasks with Grunt and more
          recently Webpack
          </li>
      </ul>
      <blockquote>
        To this date, development teams that work with Artisan Digital praise
          the unique and <b>comprehensive approach to asset delivery</b>{' '}
      </blockquote>
    </div>

    <img
      src={roadmap2015}
      alt={`roadmap`}
      className={centredImg}
    />

    <div className={readable}>
      <h2>2016 - 2018</h2>
      <p>
        During my time at Artisan Digital, I mostly worked on wagering
        based projects. The technical and complex nature of the applications
        has appealed to me. It also helped to have an interest in sports. In
        2016 Genius Sports acquired Total Betting Solutions, which was the
        end-to-end wagering solution of which Artisan Digital designed the
        front-ends. As part of this acquisition, I worked solely on
        Genius Sports products, under the Artisan Digital umbrella. During this
        time I continued to deliver the same value to the clients and
        developers I worked with. 
      </p>
    </div>

    <img
      src={roadmap2018}
      alt={`roadmap`}
      className={centredImg}
    />


    <div className={readable}>
      <h2>2019 - 2021</h2>
      <p>
      My role at Artisan Digital evolved into managing, mentoring and training others. I was in more meetings, planning and estimation sessions. Involved more in startup product design and overseeing the work of other designers. I spent more time in front of and communicating with clients. This was an invaluable learning experience. There were successes and even some projects that I wouldn't consider so. Despite my expanding role within the business, after 10 years it was time for change. I finished with Artisan Digital during 2021. After the successful completion of the Bluebet website redesign. I'll be forever grateful for my time there. From everything I learned, to the friends I made.  
      </p>
    </div>

    <img
      src={roadmap2019}
      alt={`roadmap`}
      className={centredImg}
    />

    <div className={readable}>
      <h2>2022</h2>
      <p>
      During a break from full time work I have been able to enjoy more time with my son. I balance work time between freelancing and working on my own products. A couple of which are due out in the second half of 2022. Stay tuned...
      </p>
    </div>

  </Layout>
)

export default About
